.facility-page-layout-v1 {
	margin-top: var(--page-margin-top);

	.headline {
		font-size: var(--h4-font-size);
		color: var(--color-primary);
		font-weight: 600;
	}

	.show-more-btn {
		text-align: center;
		margin-bottom: 20px;
	}

	.facility-map {
		width: 100%;
		height: var(--facility-map-height);
		position: relative;
	}

	.openstreetwarning {
		height: var(--facility-map-height) !important;
		aspect-ratio: inherit;
	}

	.title {
		font-weight: 600;
		font-size: 20px;
		padding-bottom: 10px;
	}

}