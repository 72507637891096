.contact-page-layout-v1 {
	margin-top: var(--page-margin-top);

	.inner-banner {
		&.contact {
			background-image: url(RESOURCE/img/inner-banner-contact.webp);
		}

		&.blog {
			background-image: url(RESOURCE/img/inner-banner-contact.webp);
		}
	}
}