.index-page-layout-v1 {
	margin-top: var(--page-margin-top);

	h2 {
		text-align: center;
	}

	.usp-block {
		padding-top: 30px;
	}

	.subtitle {
		text-align: center;
		text-indent: 0px;
	}

	.index-map {
		.leaflet-popup-content {
			margin: 0;
			padding: 15px;
		}
	}

	.highlights {
		display: flex;
		justify-content: space-between;

		@media (max-width:992px) {
			flex-wrap: wrap;
		}


		.highlight {
			text-align: center;
			color: var(--color-black);
			padding: 10px;
			position: relative;

			.icon {
				margin-bottom: 10px;
				margin-top: 20px;
				font-size: 36px;
				color: var(--color-primary);
				height: 54px;
			}

			.title {
				font-size: 20px;
				margin-bottom: 10px;
				font-weight: 600;
			}

			.text {
				font-size: 18px;
				padding-bottom: 30px;
				margin: 0 auto;

				@media(max-width: 1023px) {
					max-width: 300px;
				}

			}
		}
	}

}