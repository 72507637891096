.blog-page-layout-v1 {
	margin-top: var(--page-margin-top);


	.blog-header {
		margin-bottom: 10px;
	}

	h2 {
		font-size: 24px;
		margin-top: 0;
	}


	.blogentry {
		padding-top: 0;
		margin-top: 15px;
		margin-bottom: 55px;

		&.inner-container {
			margin-top: 145px;
		}

		@media (max-width:992px) {
			margin-top: 70px;
			border-top: none;
		}

		img {
			width: 100%;
			height: auto !important;
		}

		.tag-box {
			padding: 0;
			margin: 0;

			li {
				display: inline-block;
				vertical-align: middle;
				margin: 0 5px 5px 0;

				a {
					background-color: #ddd;
					padding: 5px;
					display: block;

				}
			}
		}
	}
}